import React, {Component} from 'react';
import {Link} from "react-router-dom";
import '../../../css/footer.css';
import {Affix, Button, Image} from "antd";
import ArrowUpOutlined from "@ant-design/icons/lib/icons/ArrowUpOutlined";
import {withTranslation} from "react-i18next";
import request from "../../../util/request";
import {getSession} from "../../../util/sessionUtil";
import {FieldTimeOutlined, HomeOutlined, MailOutlined, PhoneOutlined} from "@ant-design/icons";

class Footer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            background: getSession('background'),
            textColor: getSession('textColor'),
            // 联系信息
            concatInfo: {
                email: '799210382@qq.com',
                phone: '123456789',
                address: '北京市朝阳区望京SOHO塔2B座',
                enAddress: 'dubai qoiwsad alskdjalksdj ',
                workTime: '周一至周五 9:00-18:00',
                enWorkTime: 'business 9:00-18:00',
                fileVOList: [
                    {
                        fileId: 1,
                        url: '',
                    }
                ]
            },
            isEnglish: true
        }
    }

    initFooter() {
        return request({
            url: '/dubai/query/footer/home',
            method: 'get',
        })
    }

    componentDidMount() {
        console.log('footer URL', process.env.REACT_APP_path)
        this.setState({
            isEnglish: getSession('language') === 'en',
        })
        console.log('footer componentDidMount');
        this.initFooter().then(res => {
            console.log('footer', res)
            if (res.code === 200) {
                this.setState({
                    concatInfo: res.data
                })
            }
        })
    }

    render() {
        const {t} = this.props
        const {background,textColor} = this.state
        return (
            <div>
                <div
                    style={{
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '1rem 2rem',
                        backgroundColor: background,
                    }}
                >
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        padding: '2.5rem 0',
                        alignItems: 'center'
                    }
                    }>
                        {/*左边logo*/}
                        <div className="logo"
                             style={{
                                 display: 'flex',
                                 alignItems: 'center',
                                 justifyContent: 'space-between',
                                 width: '40rem',
                             }}
                        >
                            <Image width={'200px'} preview={false} src={this.state.concatInfo.fileVOList[0].url}
                                   style={{height: '50px'}}/>
                            <div className="content-box" style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                height: '10em',
                                width: '20rem',
                                justifyContent: 'space-between'
                            }}>
                                <div
                                    style={{
                                        color: textColor
                                    }}
                                    className="title">
                                    <span

                                    >{t('footer.linkTitle')}</span>
                                </div>
                                <div className="email content">
                                    <Link to='/'>{t('footer.home')}</Link>
                                </div>
                                <div className="email content">
                                    <Link to='/product'>{t('footer.product')}</Link>
                                </div>
                                <div className="email content">
                                    <Link to='/news'>{t('footer.news')}</Link>
                                </div>
                                <div className="email content">
                                    <Link to='/about'>{t('footer.about')}</Link>
                                </div>

                            </div>
                        </div>
                        {/*中间联系信息使用ant design 的组件库展示,包括公司邮箱，公司电话，公司地址，公司营业时间*/}
                        <div className="contact">
                            <div className="content-box" style={{
                                display: 'flex',
                                flexDirection: 'column',
                                textAlign: 'left',
                                alignItems: 'flex-start',
                                height: '10em',
                                justifyContent: 'space-between'
                            }}>
                                <div style={{
                                    color: textColor
                                }} className="title">
                                    <span>{t('footer.concatTitle')}</span>
                                </div>
                                {
                                    this.state.concatInfo.email !== '' && this.state.concatInfo.email !== null ?
                                        <div style={{
                                            color: textColor
                                        }}  className="email content">
                                            <span><MailOutlined/> {t('footer.email')}：</span>
                                            <span>{this.state.concatInfo.email}</span>
                                        </div>
                                        : null
                                }
                                {
                                    this.state.concatInfo.phone !== '' && this.state.concatInfo.phone !== null ?
                                        <div style={{
                                            color: textColor
                                        }}  className="email content">
                                            <span><PhoneOutlined/> {t('footer.phone')}：</span>
                                            <span>{this.state.concatInfo.phone}</span>
                                        </div>
                                        : null
                                }
                                {
                                    this.state.concatInfo.enAddress !== '' && this.state.concatInfo.enAddress !== null ?
                                        <div style={{
                                            color: textColor
                                        }}  className="email content">
                                            <span><HomeOutlined/> {t('footer.address')}：</span>
                                            <span>{this.state.isEnglish ? this.state.concatInfo.enAddress : this.state.concatInfo.address}</span>
                                        </div>
                                        : null
                                }
                                {
                                    this.state.concatInfo.enWorkTime !== '' && this.state.concatInfo.enWorkTime !== null ?
                                        <div style={{
                                            color: textColor
                                        }}  className="email content">
                                            <span><FieldTimeOutlined/> {t('footer.time')}：</span>
                                            <span>{this.state.isEnglish ? this.state.concatInfo.enWorkTime : this.state.concatInfo.workTime}</span>
                                        </div>
                                        : null

                                }


                            </div>
                        </div>
                        {/*右边链接跳转*/}
                        <div className="link">
                            {/*返回顶部*/}
                            <Affix>
                                <Button
                                    style={
                                        {
                                            background: background,
                                            color: textColor
                                        }
                                    }
                                    onClick={() => {
                                        var timer = setInterval(function () {
                                            if (document.documentElement.scrollTop !== 0) {
                                                document.documentElement.scrollTop -= 100;
                                            } else {
                                                clearInterval(timer);
                                            }
                                        }, 10);

                                    }}>
                                    <ArrowUpOutlined
                                    ></ArrowUpOutlined>Top
                                </Button>
                            </Affix>
                        </div>
                    </div>
                    <div className="footer-sign">
                        {this.state.concatInfo.websiteNum}}
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(Footer);
